// Importamos las bibliotecas necesarias
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Importamos Font Awesome
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'; // Ícono de logout

function Header() {
  // Extraemos el usuario y la función logout de Auth0
  const { user, logout } = useAuth0();

  return (
    <nav className="navbar navbar-light bg-white shadow-sm fixed-top">
      <div className="container-fluid position-relative">
        {/* Bienvenida para el usuario */}
        <div className="d-flex align-items-center ms-3">
          <span className="navbar-text d-none d-md-block">Willkommen, {user.name}</span>
        </div>

        {/* Logo centrado */}
        <div className="position-absolute top-50 start-50 translate-middle">
          <img src="https://lahnitsolutions.de/wp-content/uploads/2024/09/logo.png" alt="LIKS Logo" className="logo" />
        </div>

        {/* Botón de logout con ícono */}
        <div className="d-flex align-items-center me-3">
          <button className="btn btn-icon" onClick={() => logout({ returnTo: window.location.origin })}>
            <FontAwesomeIcon icon={faSignOutAlt} size="lg" /> {/* Ícono de logout */}
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Header;