// ChartComponent.js
import React from 'react';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin // Registra el plugin de anotaciones
);

const ChartComponent = ({
  data,
  options,
  chartRef,
  chartType,
  showReferenceLine,
  zielConsumption,
  zielCO2,
  zielDate,
}) => {
  // Añadir la fecha y valor del Ziel para Consumo y CO2
  const zielDateStr = zielDate ? zielDate.toLocaleDateString('de-DE') : '';

  const adjustedOptions = {
    ...options,
    plugins: {
      ...options.plugins,
      annotation: showReferenceLine
        ? {
            annotations: {
              line1: {
                type: 'line',
                xMin: zielDateStr, // Fecha objetivo para Consumo
                xMax: zielDateStr,
                yMin: zielConsumption, // Valor objetivo de Consumo
                yMax: zielConsumption,
                borderColor: 'blue',
                borderWidth: 2,
                label: {
                  content: `Ziel Diesel: ${zielConsumption} L`,
                  enabled: true,
                  position: 'center',
                  backgroundColor: 'rgba(0, 0, 255, 0.5)',
                },
              },
              line2: {
                type: 'line',
                xMin: zielDateStr, // Fecha objetivo para CO2
                xMax: zielDateStr,
                yMin: zielCO2, // Valor objetivo de CO2
                yMax: zielCO2,
                borderColor: 'green',
                borderWidth: 2,
                label: {
                  content: `Ziel CO₂: ${zielCO2} T`,
                  enabled: true,
                  position: 'center',
                  backgroundColor: 'rgba(0, 255, 0, 0.5)',
                },
              },
            },
          }
        : {},
    },
  };

  // Seleccionar el componente de gráfico según chartType
  const Chart = chartType === 'bar' ? Bar : Line;

  return <Chart ref={chartRef} data={data} options={adjustedOptions} />;
};

export default ChartComponent;