import React, { useEffect, useState } from 'react';
import styles from './dashboard.module.css'; // Importar el archivo CSS Module
// import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Slider from 'react-slick'; // Slider para las noticias

// Importar las imágenes necesarias
import enviromentalIcon from '../assets/enviromental.png';
import socialIcon from '../assets/social.png';
import governanceIcon from '../assets/governance.png';
import chartIcon from '../assets/chart.png';
import locationIcon from '../assets/location-icon.png';
import pollutionIcon from '../assets/pollution-icon.png';

const Dashboard = ({ onNavigate }) => {
    const [co2Data, setCo2Data] = useState(null);
    const [loading, setLoading] = useState(true);
    const [news, setNews] = useState([]);

    // Llamada a la API para obtener datos de calidad de aire
    useEffect(() => {
        const fetchAirQuality = async () => {
            try {
                const response = await fetch(
                    "https://api.openweathermap.org/data/2.5/air_pollution?lat=51.1657&lon=10.4515&appid=348ecaf4d318e55d5743ac307511f954"
                );
                const data = await response.json();
                setCo2Data(data);
                setLoading(false);
            } catch (error) {
                console.error("Fehler beim Abrufen der Luftqualitätsdaten", error);
                setLoading(false);
            }
        };

        fetchAirQuality();
        const interval = setInterval(fetchAirQuality, 60000); // Refrescar cada 60 segundos
        return () => clearInterval(interval);
    }, []);

// Llamada a la API para obtener noticias sobre ESG
useEffect(() => {
    const fetchNews = async () => {
        try {
            const response = await fetch(
                `https://gnews.io/api/v4/search?q=ESG&lang=de&token=cf16b920b1ff31a033e1264a3a3724af`
            );
            const data = await response.json();

            // Ensure the data has articles and set news
            if (data && data.articles) {
                setNews(data.articles);
            } else {
                setNews([]); // Set an empty array if no articles are available
            }
        } catch (error) {
            console.error("Fehler beim Abrufen der Nachrichten", error);
            setNews([]); // Set an empty array in case of an error
        }
    };

    fetchNews();
}, []);



    // Configuración del slider
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000, // Cambiar cada 5 segundos
    };

    return (
        <div className={styles['dashboard-container']}>
            <div className={styles['background-overlay']}></div>
            <div className={styles['grid-container']}>
                {/* Tarjeta Umwelt */}
                <div className={styles['card']} onClick={() => onNavigate('environmental')}>
                    <img src={enviromentalIcon} alt="Umwelt" className={styles['card-icon']} />
                    <div className={styles['card-body']}>
                        <h2 className={styles['card-title']}>Umwelt</h2>
                    </div>
                </div>

                {/* Tarjeta Soziales */}
                <div className={styles['card']} onClick={() => onNavigate('social')}>
                    <img src={socialIcon} alt="Soziales" className={styles['card-icon']} />
                    <div className={styles['card-body']}>
                        <h2 className={styles['card-title']}>Soziales</h2>
                    </div>
                </div>

                {/* Tarjeta Unternehmensführung */}
                <div className={styles['card']} onClick={() => onNavigate('governance')}>
                    <img src={governanceIcon} alt="Unternehmensführung" className={styles['card-icon']} />
                    <div className={styles['card-body']}>
                        <h2 className={styles['card-title']}>Unternehmensführung</h2>
                    </div>
                </div>
            </div>

            {/* Sección adicional para "Dateien hochladen und analysieren" */}
            <div className={styles['full-width-section']} onClick={() => onNavigate('analyse')}> 
                <div className={styles['card']}>
                    <img src={chartIcon} alt="Dateien hochladen" className={styles['card-icon']} />
                    <div className={styles['card-body']}>
                        <h3 className={styles['card-subtitle']}>Dateien hochladen und analysieren</h3>
                        <p className={styles['card-text']}>
                            Hier können Sie Ihre Daten hochladen, und die von LIKS entwickelte künstliche Intelligenz wird die benötigten Daten extrahieren, um Ihren CO₂-Fußabdruck zu auditieren und mehr.
                        </p>
                    </div>
                </div>
            </div>

            {/* Card del mapa con información sobre CO2 */}
            <div className={styles['full-width-section']}>
                <div className={`${styles['card']} ${styles['no-hover']}`} >

                    <div className={styles['data-bar']}>
                        
                        <div className={styles['data-item']}>
                            <img src={locationIcon} alt="Standort" className={styles['data-icon']} />
                            <span className={styles['data-text']}>Standort: Deutschland</span>
                        </div>
                        <div className={styles['data-item Co2']}>
                            <img src={pollutionIcon} alt="Luftverschmutzung" className={styles['data-icon']} />
                            {loading ? (
                                <span className={styles['data-text']}>Daten werden geladen...</span>
                            ) : co2Data && co2Data.list && co2Data.list[0] ? (
                                <span className={styles['data-text']}>
                                    CO₂-Verschmutzung: {co2Data.list[0].components.co} μg/m³
                                </span>
                            ) : (
                                <span className={styles['data-text']}>Keine Daten verfügbar</span>
                            )}
                        </div>
                       
                    </div>

                    <div className={styles['map-container']}>
                    {/* <MapContainer
                        center={[51.1657, 10.4515]}
                        zoom={6}
                        scrollWheelZoom={false}
                        dragging={false}
                        touchZoom={false}
                        doubleClickZoom={false}
                        keyboard={false}
                        boxZoom={false}
                        zoomControl={false}
                        style={{ height: '200px', width: '100%' }}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution="&copy; OpenStreetMap contributors"
                        />
                    </MapContainer> */}
                </div>

                 
                    
                </div>
            </div>

 
<div className={styles['full-width-section']}>
    <div className={`${styles['card']} ${styles['no-hover']}`}>
        <Slider {...settings}>
            {news && news.length > 0 ? (
                news.map((article, index) => (
                    <div key={index} className={styles['news-slide']}>
                        <div className={styles['news-content']}>
                            <h4 className={styles['news-title']}>{article.title}</h4>
                            <p className={styles['news-description']}>{article.description}</p>
                            <a href={article.url} target="_blank" rel="noopener noreferrer">
                                Artikel lesen
                            </a>
                        </div>
                    </div>
                ))
            ) : (
                <div className={styles['news-slide']}>
                    <p className={styles['news-description']}>Keine Nachrichten verfügbar</p>
                </div>
            )}
        </Slider>
    </div>
</div>
        </div>
    );
};

export default Dashboard;
