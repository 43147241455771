// Environmental.js
import React, { useState } from 'react';
import FactorGraph from './FactorGraph';
import { ButtonGroup, Button } from '@mui/material';

/**
 * Componente principal que muestra múltiples gráficos de factores ambientales.
 * Los gráficos se organizan en dos columnas.
 */
const Environmental = () => {
  // Estado para controlar el modo de vista (días, meses, años)
  const [viewMode, setViewMode] = useState('monthly');

  // Función para cambiar el modo de vista
  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };

  return (
    <div className='container-fluid mt-4'>
      {/* Botón centrado con estilo mejorado */}
      <div className='d-flex justify-content-center mb-4'>
        <ButtonGroup
          variant="contained"
          color="primary"
          sx={{
            borderRadius: '20px',
            boxShadow: 3,
          }}
        >
          <Button
            onClick={() => handleViewModeChange('daily')}
            variant={viewMode === 'daily' ? 'contained' : 'outlined'}
            sx={{ borderRadius: '20px 0 0 20px' }}
          >
            Tage
          </Button>
          <Button
            onClick={() => handleViewModeChange('monthly')}
            variant={viewMode === 'monthly' ? 'contained' : 'outlined'}
          >
            Monate
          </Button>
          <Button
            onClick={() => handleViewModeChange('yearly')}
            variant={viewMode === 'yearly' ? 'contained' : 'outlined'}
            sx={{ borderRadius: '0 20px 20px 0' }}
          >
            Jahre
          </Button>
        </ButtonGroup>
      </div>

      <div className='row align-items-start'>
        {/* Primera columna */}
        <div className='col-md-6'>
          <div className='row'>
            <div className='col-12'>
              <FactorGraph
                factor='benzin'
                factorLabel='Benzinverbrauch'
                unit='Liter'
                viewMode={viewMode}
              />
            </div>
            <div className='col-12'>
              <FactorGraph
                factor='diesel'
                factorLabel='Dieselverbrauch'
                unit='Liter'
                viewMode={viewMode}
              />
            </div>
            <div className='col-12'>
              <FactorGraph
                factor='erdgas'
                factorLabel='Erdgasverbrauch'
                unit='m³'
                viewMode={viewMode}
              />
            </div>
            <div className='col-12'>
              <FactorGraph
                factor='wasserverbrauch'
                factorLabel='Wasserverbrauch'
                unit='m³'
                viewMode={viewMode}
              />
            </div>
            <div className='col-12'>
              <FactorGraph
                factor='erzeugte_abfaelle'
                factorLabel='Erzeugte Abfälle'
                unit='Liter'
                viewMode={viewMode}
              />
            </div>
            <div className='col-12'>
              <FactorGraph
                factor='kaeltemittel'
                factorLabel='Kältemittelverbrauch'
                unit='kg'
                viewMode={viewMode}
              />
            </div>
          </div>
        </div>
        {/* Segunda columna */}
        <div className='col-md-6'>
          <div className='row'>
          <div className='col-12'>
              <FactorGraph
                factor='nutzung_erneuerbarer_energien'
                factorLabel='Nutzung Erneuerbarer Energien'
                unit='kWh'
                viewMode={viewMode}
              />
            </div>
            <div className='col-12'>
              <FactorGraph
                factor='stromverbrauch'
                factorLabel='Stromverbrauch'
                unit='kWh'
                viewMode={viewMode}
              />
            </div>
            <div className='col-12'>
              <FactorGraph
                factor='technologienutzung'
                factorLabel='Technologienutzung'
                unit='Stunden'
                viewMode={viewMode}
              />
            </div>
            <div className='col-12'>
              <FactorGraph
                factor='transport_produkte'
                factorLabel='Transport der Produkte'
                unit='km'
                viewMode={viewMode}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Environmental;