// FactorGraph.js
import React, { useState, useEffect, useRef } from 'react';
import ChartComponent from './ChartComponent';
import axios from 'axios';
import { Typography } from '@mui/material'; // Importieren von Typography

const FactorGraph = ({ factor, factorLabel, unit, viewMode }) => {
  const [data, setData] = useState(null); // Anfangs null
  const [options, setOptions] = useState({});
  const chartRef = useRef(null);

  // Zustand zum Speichern der Analyseergebnisse
  const [analysisResults, setAnalysisResults] = useState([]);

  // Funktion zum Abrufen der Daten von der Datenbank
  const fetchAnalysisResults = async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/analysis-results/`);
      const { results } = response.data;
      setAnalysisResults(results);
    } catch (error) {
      console.error('Fehler beim Abrufen der Daten:', error);
    }
  };

  // Daten beim Mounten des Komponenten abrufen
  useEffect(() => {
    fetchAnalysisResults();
  }, []);

  // Daten verarbeiten, jedes Mal wenn sich die Analyseergebnisse oder der viewMode ändern
  useEffect(() => {
    const gefilterteErgebnisse = analysisResults.filter(
      (result) => result.factor === factor
    );

    if (gefilterteErgebnisse.length === 0) {
      setData(null);
      return;
    }

    // Ergebnisse verarbeiten, um Einträge mit Zeiträumen zu handhaben
    let verarbeiteteErgebnisse = [];

    gefilterteErgebnisse.forEach((result) => {
      // Wenn das Ergebnis ein 'date' hat, direkt verwenden
      if (result.date) {
        const resultDate = new Date(result.date);
        verarbeiteteErgebnisse.push({
          date: formatMonthYear(resultDate), // Formatiert als Zeichenkette
          value: Number(result.value),
          co2_aequivalent: Number(result.co2_aequivalent),
        });
      } else if (result.start_date && result.end_date) {
        const start = new Date(result.start_date);
        const end = new Date(result.end_date);
        const totalDays = Math.floor((end - start) / (1000 * 60 * 60 * 24)) + 1;
        if (totalDays <= 0) return;

        // Wert je nach viewMode verteilen
        if (viewMode === 'daily') {
          // Wert auf jeden Tag verteilen
          let currentDate = new Date(start);
          const dailyValue = Number(result.value) / totalDays;
          const dailyCO2 = Number(result.co2_aequivalent) / totalDays;

          while (currentDate <= end) {
            verarbeiteteErgebnisse.push({
              date: currentDate.toISOString().split('T')[0], // Format 'YYYY-MM-DD'
              value: dailyValue,
              co2_aequivalent: dailyCO2,
            });
            currentDate.setDate(currentDate.getDate() + 1);
          }
        } else if (viewMode === 'monthly') {
          // Wert auf Monate verteilen
          let currentDate = new Date(start);
          const endMonth = end.getMonth() + end.getFullYear() * 12;
          const startMonth = start.getMonth() + start.getFullYear() * 12;
          const totalMonths = endMonth - startMonth + 1;
          const monthlyValue = Number(result.value) / totalMonths;
          const monthlyCO2 = Number(result.co2_aequivalent) / totalMonths;

          while (currentDate <= end) {
            const monthKey = formatMonthYear(currentDate);
            verarbeiteteErgebnisse.push({
              date: monthKey, // Formatiert als Zeichenkette
              value: monthlyValue,
              co2_aequivalent: monthlyCO2,
            });
            // Zum nächsten Monat wechseln
            currentDate.setMonth(currentDate.getMonth() + 1);
          }
        } else if (viewMode === 'yearly') {
          // Wert auf Jahre verteilen
          let currentYear = start.getFullYear();
          const endYear = end.getFullYear();
          const totalYears = endYear - currentYear + 1;
          const yearlyValue = Number(result.value) / totalYears;
          const yearlyCO2 = Number(result.co2_aequivalent) / totalYears;

          while (currentYear <= endYear) {
            verarbeiteteErgebnisse.push({
              date: currentYear.toString(), // Jahreszahl als Zeichenkette
              value: yearlyValue,
              co2_aequivalent: yearlyCO2,
            });
            currentYear++;
          }
        }
      }
    });

    // Daten für das Diagramm vorbereiten
    let datenLabels = [];
    let werte = [];
    let co2Aequivalent = [];

    if (viewMode === 'daily') {
      // Ergebnisse nach Datum sortieren
      const sortierteErgebnisse = verarbeiteteErgebnisse.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );

      datenLabels = sortierteErgebnisse.map((result) => result.date); // 'YYYY-MM-DD'
      werte = sortierteErgebnisse.map((result) => result.value);
      co2Aequivalent = sortierteErgebnisse.map((result) => result.co2_aequivalent);
    } else if (viewMode === 'monthly') {
      const monatlicheWerte = {};
      const monatlicheCO2 = {};

      verarbeiteteErgebnisse.forEach((result) => {
        const monthKey = result.date; // Formatiert als 'Jan 2023'
        if (!monatlicheWerte[monthKey]) {
          monatlicheWerte[monthKey] = 0;
          monatlicheCO2[monthKey] = 0;
        }
        monatlicheWerte[monthKey] += result.value;
        monatlicheCO2[monthKey] += result.co2_aequivalent;
      });

      // Monatsschlüssel sortieren
      const sortierteMonate = Object.keys(monatlicheWerte)
        .sort((a, b) => {
          const [monatA, jahrA] = a.split(' ');
          const [monatB, jahrB] = b.split(' ');
          const dateA = new Date(`${monatA} 1, ${jahrA}`);
          const dateB = new Date(`${monatB} 1, ${jahrB}`);
          return dateA - dateB;
        });

      datenLabels = sortierteMonate;
      werte = sortierteMonate.map((monthKey) => monatlicheWerte[monthKey]);
      co2Aequivalent = sortierteMonate.map((monthKey) => monatlicheCO2[monthKey]);
    } else if (viewMode === 'yearly') {
      const jährlicheWerte = {};
      const jährlicheCO2 = {};

      verarbeiteteErgebnisse.forEach((result) => {
        const yearKey = result.date; // Jahreszahl als Zeichenkette
        if (!jährlicheWerte[yearKey]) {
          jährlicheWerte[yearKey] = 0;
          jährlicheCO2[yearKey] = 0;
        }
        jährlicheWerte[yearKey] += result.value;
        jährlicheCO2[yearKey] += result.co2_aequivalent;
      });

      const sortierteJahre = Object.keys(jährlicheWerte).sort();

      datenLabels = sortierteJahre;
      werte = sortierteJahre.map((yearKey) => jährlicheWerte[yearKey]);
      co2Aequivalent = sortierteJahre.map((yearKey) => jährlicheCO2[yearKey]);
    }

    if (datenLabels.length === 0) {
      setData(null);
      return;
    }

    setData({
      labels: datenLabels,
      datasets: [
        {
          label: `${factorLabel} (${unit})`,
          data: werte,
          fill: true,
          backgroundColor: 'rgba(58,123,213,0.1)',
          borderColor: 'rgba(58,123,213,1)',
          tension: 0.4,
          yAxisID: 'y',
        },
        {
          label: 'CO₂-Äquivalent (Tonnen)',
          data: co2Aequivalent,
          fill: true,
          backgroundColor: 'rgba(255,99,132,0.1)',
          borderColor: 'rgba(255,99,132,1)',
          tension: 0.4,
          yAxisID: 'y1',
        },
      ],
    });

    // Optionen für das Diagramm festlegen
    setOptions({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
        },
        tooltip: {
          mode: 'index',
          intersect: false,
        },
      },
      scales: {
        x: {
          type: 'category', // Stellt sicher, dass die X-Achse die Labels als Kategorien behandelt
          grid: {
            display: false,
          },
          ticks: {
            display: false, // Versteckt die X-Achsen-Labels
          },
        },
        y: {
          type: 'linear',
          position: 'left',
          beginAtZero: true,
          title: {
            display: true,
            text: unit,
          },
        },
        y1: {
          type: 'linear',
          position: 'right',
          beginAtZero: true,
          title: {
            display: true,
            text: 'CO₂-Äquivalent (Tonnen)',
          },
          grid: {
            drawOnChartArea: false,
          },
        },
      },
    });
  }, [analysisResults, viewMode, factor, factorLabel, unit]);

  // Variable für das Ziel (Zielwert)
  const showReferenceLine = false; // Passen Sie dies nach Bedarf an
  const zielConsumption = 100; // Zielwert für den Verbrauch
  const zielCO2 = 50; // Zielwert für CO₂
  const zielDate = new Date(); // Ziel-Datum

  // Wenn keine Daten vorhanden sind, wird das Komponent nicht gerendert
  if (!data) {
    return null;
  }

  return (
    <div className='mb-4'>
      {/* Minimalistischer Titel */}
      <Typography
        variant="caption" // Kleine Schriftgröße
        align="center"
        gutterBottom
        sx={{
          textTransform: 'capitalize', // Stellt sicher, dass der erste Buchstabe groß ist
          fontWeight: '500', // Leicht fettere Schrift
          color: 'text.secondary', // Sekundäre Farbe für ein minimalistisches Aussehen
        }}
      >
        {factorLabel}
      </Typography>

      <div
        className='chart-container'
        style={{ height: '400px', marginBottom: '20px' }}
      >
        <ChartComponent
          data={data}
          options={options}
          chartRef={chartRef}
          chartType={'line'} // Sie können den Diagrammtyp bei Bedarf anpassen
          showReferenceLine={showReferenceLine}
          zielConsumption={zielConsumption}
          zielCO2={zielCO2}
          zielDate={zielDate}
        />
      </div>
    </div>
  );
};

// Hilfsfunktion zur Formatierung von Monat und Jahr auf Deutsch
const formatMonthYear = (date) => {
  const options = { month: 'short', year: 'numeric' };
  return date.toLocaleString('de-DE', options);
};

export default FactorGraph;