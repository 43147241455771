// Berichte.js
import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import './Berichte.css';

const Berichte = () => {
  return (
    <div className="berichte-container">
      <div className="info-card">
        <FaInfoCircle className="info-icon" />
        <h2>Keine Berichte verfügbar</h2>
        <p>
          Derzeit liegen keine Berichte vor. Bitte beachten Sie, dass unsere Software eine Mindestmenge an Daten benötigt, um Berichte zu erstellen. Wie in der Sektion <strong>Berichterstattung</strong> erwähnt, wird die Funktion zur Berichtserstellung ab der Beta-Version 1.0.0 am <strong>23.10.2024</strong> vollständig verfügbar sein.
        </p>
        <p>
          Bis dahin empfehlen wir Ihnen, weiterhin Daten hinzuzufügen und unsere Plattform zu erkunden. Bei Fragen oder Anregungen stehen wir Ihnen gerne zur Verfügung.
        </p>
      </div>
    </div>
  );
};

export default Berichte;