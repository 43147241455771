// GlobaleEinstellungen.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FaInfoCircle } from 'react-icons/fa';
import '../GlobaleEinstellungen.css';
import axios from 'axios'; // Axios importieren

const GlobaleEinstellungen = () => {
    const { user } = useAuth0();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const email = user?.email || '';
    const picture = user?.picture || '';
    const nickname = user?.nickname || '';

    useEffect(() => {
        if (user && user.name) {
            const [first, ...last] = user.name.split(' ');
            setFirstName(first);
            setLastName(last.join(' '));
        }
    }, [user]);

    // Funktion zum Löschen der Analysedaten
    const handleDeleteAnalysisData = async () => {
        if (window.confirm('Sind Sie sicher, dass Sie alle Analysedaten löschen möchten?')) {
            try {
                const backendUrl = process.env.REACT_APP_BACKEND_URL;
                const response = await axios.delete(`${backendUrl}/analyse/delete-analysis-data/`);
                alert(response.data.message);
            } catch (error) {
                console.error('Fehler beim Löschen der Analysedaten:', error);
                alert('Fehler beim Löschen der Analysedaten. Bitte versuchen Sie es erneut.');
            }
        }
    };

    return (
        <div className="settings-container">
            <div className="section">
                <h2>Profildetails</h2>
                <form id="profile-form">
                    <div className="form-group">
                        <img src={picture} alt="Profilbild" className="profile-picture" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="first-name">Vorname</label>
                        <input
                            type="text"
                            className="form-control"
                            id="first-name"
                            name="first-name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                            disabled
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="last-name">Nachname</label>
                        <input
                            type="text"
                            className="form-control"
                            id="last-name"
                            name="last-name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            disabled
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">E-Mail-Adresse</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={email}
                            required
                            disabled
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="nickname">Benutzername</label>
                        <input
                            type="text"
                            className="form-control"
                            id="nickname"
                            name="nickname"
                            value={nickname}
                            disabled
                        />
                    </div>
                </form>
            </div>

            <div className="section">
                {/* Erweiterte Informationskarte */}
                <div className="info-card" style={{ padding: '20px', borderRadius: '10px', backgroundColor: '#f8f9fa', marginTop: '20px' }}>
                    <FaInfoCircle className="info-icon" style={{ fontSize: '24px', color: '#007bff', marginBottom: '10px' }} />
                    <h2>Aktualisierung am 15.10.2024</h2>
                    <p style={{ fontSize: '16px' }}>
                        Am <strong>15.10.2024</strong> wird die Version Alpha 1.3.0 aktualisiert. In dieser Version
                        können Sie Benutzer hinzufügen oder entfernen und alle relevanten Daten bearbeiten,
                        die derzeit in der Implementierungsdatenbank gespeichert sind.
                    </p>
                </div>
            </div>

            {/* Neuer Abschnitt für den Lösch-Button */}
            <div className="section">
                <h2>Datenverwaltung</h2>
                <button className="btn btn-danger" onClick={handleDeleteAnalysisData}>
                    Analysedaten löschen
                </button>
            </div>
        </div>
    );
};

export default GlobaleEinstellungen;