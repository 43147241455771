import React, { useState, useEffect, useRef } from 'react';
import styles from './AIchat.module.css'; // Importar el archivo CSS Module

const AIchat = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const chatboxRef = useRef(null);
    const [userId, setUserId] = useState('');

    // Genera o obtiene un ID de usuario único
    useEffect(() => {
        let storedUserId = localStorage.getItem('user_id');
        if (!storedUserId) {
            storedUserId = 'user_' + Date.now();
            localStorage.setItem('user_id', storedUserId);
        }
        setUserId(storedUserId);
    }, []);

    const appendMessage = (sender, text) => {
        setMessages((prevMessages) => [...prevMessages, { sender, text }]);
    };

    const sendMessage = async () => {
        if (input.trim() === '') return;
        const messageText = input;
        appendMessage('You', messageText);
        setInput('');

        try {
            const response = await fetch('https://liks-server-l0001-cperbgfbezgzanfm.germanywestcentral-01.azurewebsites.net/chat', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Agrega aquí cualquier cabecera adicional que necesites, como tokens de autenticación
                },
                body: JSON.stringify({ question: messageText, user_id: userId }),
            });
            const data = await response.json();
            if (response.ok) {
                appendMessage('AI', data.response);
            } else {
                console.error('Fehler beim Verbinden mit dem Server. Bitte versuche es später noch einmal:', data.error);
                appendMessage('AI', 'Fehler beim Verbinden mit dem Server. Bitte versuche es später noch einmal.');
            }
        } catch (error) {
            console.error('Fehler beim Verbinden mit dem Server. Bitte versuche es später noch einmal:', error);
            appendMessage('AI', 'Fehler beim Verbinden mit dem Server. Bitte versuche es später noch einmal.');
        }
    };

    useEffect(() => {
        if (chatboxRef.current) {
            // Desplazar hacia abajo cuando se agregue un nuevo mensaje
            chatboxRef.current.scrollTop = 0;
        }
    }, [messages]);

    return (
        <div className={styles['chat-container']}>
            <div className={styles['chatbox']} ref={chatboxRef}>
                {messages.slice(0).reverse().map((msg, index) => (
                    <div
                        key={index}
                        className={`${styles['message']} ${
                            msg.sender === 'You' ? styles['user'] : styles['ai']
                        }`}
                    >
                        <div className={styles['message-content']}>
                            {msg.text}
                        </div>
                    </div>
                ))}
            </div>
            {/* Texto informativo en alemán */}
            <p className={styles['info-text']}>
                Dies ist ein an die LIKS GmbH angepasstes künstliches Intelligenzmodell unter der Datenschutz-Grundverordnung (DSGVO). Es kann Fehler und Inkonsistenzen aufweisen und befindet sich in der Testphase.
            </p>
            <div className={styles['input-container']}>
                <input
                    type="text"
                    placeholder="Schreiben Sie hier Ihre Nachricht..."
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') sendMessage();
                    }}
                />
                <button onClick={sendMessage}>Senden</button>
            </div>
        </div>
    );
};

export default AIchat;