// DataContent.js

import React from 'react';
import '../DataContent.css';


const DataContent = ({ onNavigate }) => {
    if (!onNavigate) {
        console.error('onNavigate no se ha pasado como prop a DataContent.');
        return null; // o mostrar algún contenido alternativo
    }

    return (
        <div className="data-content-container">
         

  
            {/* Tarjetas existentes */}
            <div className="card m-2" onClick={() => onNavigate('benzinverbrauch')}>
                <div className="card-body d-flex justify-content-between align-items-center ">
                    <span>Benzinverbrauch</span>
                    <i className="bi bi-chevron-right"></i>
                </div>
            </div>

            <div className="card m-2" onClick={() => onNavigate('dieselverbrauch')}>
                <div className="card-body d-flex justify-content-between align-items-center">
                    <span>Dieselverbrauch</span>
                    <i className="bi bi-chevron-right"></i>
                </div>
            </div>

            <div className="card m-2" onClick={() => onNavigate('elektrizitaet')}>
                <div className="card-body d-flex justify-content-between align-items-center">
                    <span>Stromverbrauch</span>
                    <i className="bi bi-chevron-right"></i>
                </div>
            </div>

            <div className="card m-2" onClick={() => onNavigate('erdgas')}>
                <div className="card-body d-flex justify-content-between align-items-center">
                    <span>Erdgas</span>
                    <i className="bi bi-chevron-right"></i>
                </div>
            </div>

            <div className="card m-2" onClick={() => onNavigate('heizoel')}>
    <div className="card-body d-flex justify-content-between align-items-center">
        <span>Heizöl</span>
        <i className="bi bi-chevron-right"></i>
    </div>
</div>


            <div className="card m-2" onClick={() => onNavigate('wasserverbrauch')}>
                <div className="card-body d-flex justify-content-between align-items-center">
                    <span>Wasserverbrauch</span>
                    <i className="bi bi-chevron-right"></i>
                </div>
            </div>

            <div className="card m-2" onClick={() => onNavigate('erzeugte-abfaelle')}>
                <div className="card-body d-flex justify-content-between align-items-center">
                    <span>Erzeugte Abfälle</span>
                    <i className="bi bi-chevron-right"></i>
                </div>
            </div>

            {/* <div className="card" onClick={() => onNavigate('geschaeftsreisen')}>
                <div className="card-body d-flex justify-content-between align-items-center">
                    <span>Geschäftsreisen (Flugreisen)</span>
                    <i className="bi bi-chevron-right"></i>
                </div>
            </div> */}

            
            <div className="card m-2" onClick={() => onNavigate('papierverbrauch')}>
                <div className="card-body d-flex justify-content-between align-items-center">
                    <span>Papierverbrauch</span>
                    <i className="bi bi-chevron-right"></i>
                </div>
            </div>
           
{/* 
            <div className="card" onClick={() => onNavigate('technologienutzung')}>
                <div className="card-body d-flex justify-content-between align-items-center">
                    <span>Technologienutzung (Server)</span>
                    <i className="bi bi-chevron-right"></i>
                </div>
            </div> */}

            {/* <div className="card" onClick={() => onNavigate('kunststoffproduktion')}>
                <div className="card-body d-flex justify-content-between align-items-center">
                    <span>Kunststoffproduktion</span>
                    <i className="bi bi-chevron-right"></i>
                </div>
            </div> */}

            {/* <div className="card" onClick={() => onNavigate('kaeltemittel')}>
                <div className="card-body d-flex justify-content-between align-items-center">
                    <span>Kältemittel (Nutzung von Kühlgeräten)</span>
                    <i className="bi bi-chevron-right"></i>
                </div>
            </div> */}
{/* 
            <div className="card" onClick={() => onNavigate('transport-produkte')}>
                <div className="card-body d-flex justify-content-between align-items-center">
                    <span>Transport von Produkten</span>
                    <i className="bi bi-chevron-right"></i>
                </div>
            </div> */}
{/* 
            <div className="card" onClick={() => onNavigate('verbrauch-landwirtschaftliche-produkte')}>
                <div className="card-body d-flex justify-content-between align-items-center">
                    <span>Verbrauch von landwirtschaftlichen Produkten</span>
                    <i className="bi bi-chevron-right"></i>
                </div>
            </div> */}

            {/* 
            <div className="card" onClick={() => onNavigate('produktionsemissionen')}>
                <div className="card-body d-flex justify-content-between align-items-center">
                    <span>Produktionsemissionen (Fabriken und Anlagen)</span>
                    <i className="bi bi-chevron-right"></i>
                </div>
            </div>
            */}

            {/* <div className="card" onClick={() => onNavigate('nutzung-erneuerbarer-energien')}>
                <div className="card-body d-flex justify-content-between align-items-center">
                    <span>Nutzung erneuerbarer Energien</span>
                    <i className="bi bi-chevron-right"></i>
                </div>
            </div> */}
        </div>
    );
};

export default DataContent;