import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App.js';
// index.js o App.js
import 'bootstrap/dist/css/bootstrap.min.css';




const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience="https://dev-tftfltcthnzd0rlq.eu.auth0.com/api/v2/"  // <- Asegúrate de usar el valor correcto
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
