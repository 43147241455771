import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from '@chakra-ui/react';
import { FaTools } from 'react-icons/fa';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import GlobaleEinstellungen from './components/GlobaleEinstellungen';
import DataContent from './components/DataContent';
import Benzinverbrauch from './components/Benzinverbrauch';
import Dieselverbrauch from './components/dieselverbrauch';
import Elektrizitaet from './components/Elektrizitaet';
import Erdgas from './components/Erdgas';
import Heizoel from './components/Heizoel';
import Wasserverbrauch from './components/Wasserverbrauch';
import ErzeugteAbfaelle from './components/ErzeugteAbfaelle';
import Geschaeftsreisen from './components/Geschaeftsreisen';
import Papierverbrauch from './components/Papierverbrauch';
import Kunststoffproduktion from './components/Kunststoffproduktion';
import Kaeltemittel from './components/Kaeltemittel';
import TransportProdukte from './components/TransportProdukte';
import VerbrauchLandwirtschaftlicheProdukte from './components/VerbrauchLandwirtschaftlicheProdukte';
import Produktionsemissionen from './components/Produktionsemissionen';
import Environmental from './components/Environmental';
import Dashboard from './components/Dashboard';
import Berichterstattung from './components/Berichterstattung';
import Berichte from './components/Berichte';
import Documentation from './components/Documentation';
import Analyse from './components/Analyse';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AIchat from './components/AIchat';
import Social from './components/Social';
import Governance from './components/Governance';

function App() {
    const { isAuthenticated, loginWithRedirect, isLoading, user } = useAuth0();
    const [isUpdating] = useState(true); // Estado para controlar si se está actualizando
    const [activePage, setActivePage] = useState('dashboard');
    const [sidebarVisible, setSidebarVisible] = useState(true);

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect();
        }
    }, [isAuthenticated, isLoading, loginWithRedirect]);

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    const renderContent = () => {
        switch (activePage) {
            case 'dashboard':
                return <Dashboard onNavigate={setActivePage} />;
            case 'settings':
                return <GlobaleEinstellungen />;
            case 'berichterstattung':
                return <Berichterstattung onNavigate={setActivePage} />;
            case 'berichte':
                return <Berichte onNavigate={setActivePage} />;
            case 'environmental':
                return <Environmental onNavigate={setActivePage} />;
            case 'social':
                return <Social onNavigate={setActivePage} />;
            case 'governance':
                return <Governance onNavigate={setActivePage} />;
            case 'data':
                return <DataContent onNavigate={setActivePage} />;
            case 'benzinverbrauch':
                return <Benzinverbrauch onNavigate={setActivePage} />;
            case 'dieselverbrauch':
                return <Dieselverbrauch onNavigate={setActivePage} />;
            case 'elektrizitaet':
                return <Elektrizitaet onNavigate={setActivePage} />;
            case 'erdgas':
                return <Erdgas onNavigate={setActivePage} />;
            case 'heizoel':
                return <Heizoel onNavigate={setActivePage} />;
            case 'wasserverbrauch':
                return <Wasserverbrauch onNavigate={setActivePage} />;
            case 'erzeugte-abfaelle':
                return <ErzeugteAbfaelle onNavigate={setActivePage} />;
            case 'geschaeftsreisen':
                return <Geschaeftsreisen onNavigate={setActivePage} />;
            case 'papierverbrauch':
                return <Papierverbrauch onNavigate={setActivePage} />;
            case 'kunststoffproduktion':
                return <Kunststoffproduktion onNavigate={setActivePage} />;
            case 'kaeltemittel':
                return <Kaeltemittel onNavigate={setActivePage} />;
            case 'transport-produkte':
                return <TransportProdukte onNavigate={setActivePage} />;
            case 'verbrauch-landwirtschaftliche-produkte':
                return <VerbrauchLandwirtschaftlicheProdukte onNavigate={setActivePage} />;
            case 'produktionsemissionen':
                return <Produktionsemissionen onNavigate={setActivePage} />;
            case 'documentation':
                return <Documentation onNavigate={setActivePage} />;
            case 'analyse':
                return <Analyse onNavigate={setActivePage} />;
            case 'aichat':
                return <AIchat onNavigate={setActivePage} />;
            default:
                return <div>Dashboard Content</div>;
        }
    };

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner size="xl" />
            </div>
        );
    }

    // Página de actualización
    if (isAuthenticated && isUpdating) {
        return (
            <div className="update-screen d-flex justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#f0f4f7' }}>
                <div className="text-center">
                    <FaTools size={50} color="#343a40" className="mb-4" />
                    <h2 style={{ fontSize: '2rem', color: '#343a40' }}>Hallo {user?.name},</h2>
                    <p style={{ fontSize: '1.2rem', color: '#555' }}>Wir führen gerade eine Systemaktualisierung durch, um Ihnen ein besseres Erlebnis zu bieten.</p>
                    <p style={{ fontSize: '1rem', color: '#777' }}>Sobald die Aktualisierung abgeschlossen ist, werden wir Ihnen eine E-Mail schicken.</p>
                    <p style={{ fontSize: '1rem', color: '#777' }}>Vielen Dank für Ihre Geduld.</p>
                    <p style={{ fontWeight: 'bold', color: '#343a40' }}>Support LIKS</p>
                    <p style={{ fontSize: '1rem', color: '#555' }}>Kontaktieren Sie uns: <a href="mailto:support@liks.ai">support@liks.ai</a></p>
                </div>
            </div>
        );
    }

    return (
        <div className={`App ${isAuthenticated ? (sidebarVisible ? 'sidebar-open' : 'sidebar-closed') : ''}`}>
            {/* Código comentado para la actualización */}
            {isAuthenticated && (
                <>
                    <Sidebar activePage={activePage} onSelectPage={setActivePage} />
                    <div className={`content-area flex-grow-1 ${sidebarVisible ? 'with-sidebar' : 'without-sidebar'}`}>
                        <Header />
                        <div className="">
                            {renderContent()}
                        </div>
                    </div>
                    <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
                        <i className={`bi ${sidebarVisible ? 'bi-chevron-left' : 'bi-chevron-right'}`}></i>
                    </button>
                </>
            )}
        </div>
    );
}

export default App;