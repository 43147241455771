import React from 'react';

function Sidebar({ activePage, onSelectPage }) {
  return (
    <div className="sidebar bg-light border-right">
      <nav className="nav flex-column">
        
        <a 
          href="#dashboard" 
          className={`nav-link ${activePage === 'dashboard' ? 'active' : ''}`} 
          onClick={() => onSelectPage('dashboard')}
        >
          <i className="bi bi-house-door-fill"></i> Home
        </a>
        <a 
          href="#aichat" 
          className={` ai-button nav-link ${activePage === 'aichat' ? 'active' : ''}`} 
          onClick={() => onSelectPage('aichat')}
        >
          <i className="bi bi-robot"></i> KI-Assistent
        </a>

        <span className="text-muted section-title">Berichte</span>
        <a 
          href="#berichterstattung" 
          className={`nav-link ${activePage === 'berichterstattung' ? 'active' : ''}`} 
          onClick={() => onSelectPage('berichterstattung')}
        >
          <i className="bi bi-file-earmark-text"></i> Berichterstattung
        </a>
        <a 
          href="#berichte" 
          className={`nav-link ${activePage === 'berichte' ? 'active' : ''}`} 
          onClick={() => onSelectPage('berichte')}
        >
          <i className="bi bi-folder"></i> Berichte
        </a>

        <span className="text-muted section-title">Daten</span>
        <a 
          href="#data" 
          className={`nav-link ${activePage === 'data' ? 'active' : ''}`} 
          onClick={() => onSelectPage('data')}
        >
          <i className="bi bi-bar-chart-line"></i> Faktoren
        </a>
        <a 
          href="#analyse" 
          className={`nav-link ${activePage === 'analyse' ? 'active' : ''}`} 
          onClick={() => onSelectPage('analyse')}
        >
          <i className="bi bi-graph-up-arrow"></i> Analyse
        </a>

        <span className="text-muted section-title">Nachhaltigkeit</span>
        <a 
          href="#umwelt" 
          className={`nav-link ${activePage === 'environmental' ? 'active' : ''}`} 
          onClick={() => onSelectPage('environmental')}
        >
          <i className="bi bi-globe"></i> Umwelt
        </a>
        <a 
          href="#sozial" 
          className={`nav-link ${activePage === 'social' ? 'active' : ''}`} 
          onClick={() => onSelectPage('social')}
        >
          <i className="bi bi-people"></i> Soziales
        </a>
        <a 
          href="#verwaltung" 
          className={`nav-link ${activePage === 'governance' ? 'active' : ''}`} 
          onClick={() => onSelectPage('governance')}
        >
          <i className="bi bi-shield-lock"></i> Verwaltung
        </a>

        <span className="text-muted section-title">Einstellungen</span>
        <a 
          href="#settings" 
          className={`nav-link ${activePage === 'settings' ? 'active' : ''}`} 
          onClick={() => onSelectPage('settings')}
        >
          <i className="bi bi-gear"></i> Globale Einstellungen
        </a>

        <span className="text-muted section-title">Weitere Informationen</span>
        <a 
          href="#documentation" 
          className={`nav-link ${activePage === 'documentation' ? 'active' : ''}`} 
          onClick={() => onSelectPage('documentation')}
        >
          <i className="bi bi-book"></i> Dokumentation
        </a>
      </nav>
    </div>
  );
}

export default Sidebar;