// Stromverbrauch.js
import React, { useState, useEffect, useRef } from 'react';
import ChartComponent from './ChartComponent';
import ControlsComponent from './ControlsComponent';
import axios from 'axios';

const Stromverbrauch = () => {
  const [viewMode, setViewMode] = useState('daily');
  const [data, setData] = useState(null); // Inicialmente null
  const [options, setOptions] = useState({});
  const [chartType, setChartType] = useState('line');
  const [downloadFormat, setDownloadFormat] = useState('png');
  const chartRef = useRef(null);

  // Estado para almacenar los resultados de análisis
  const [analysisResults, setAnalysisResults] = useState([]);

  // Función para obtener los datos desde la BD
  const fetchAnalysisResults = async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await axios.get(`${backendUrl}/analyse/analysis-results/`);
      const { results } = response.data;
      setAnalysisResults(results);
    } catch (error) {
      console.error('Fehler beim Abrufen der Daten:', error);
    }
  };

  // Obtener los datos al montar el componente
  useEffect(() => {
    fetchAnalysisResults();
  }, []);

  // Función auxiliar para formatear las fechas (solo meses y años en alemán)
  const formatMonthYear = (date) => {
    const options = { month: 'short', year: 'numeric' };
    return date.toLocaleString('de-DE', options);
  };

  // Procesar los datos cada vez que cambien los filtros o los resultados
  useEffect(() => {
    const filteredAnalysisResults = analysisResults.filter(
      (result) => result.factor === 'stromverbrauch'
    );

    // Procesar los resultados para manejar entradas con períodos
    let processedResults = [];

    filteredAnalysisResults.forEach((result) => {
      // Si el resultado tiene un 'date', usarlo directamente
      if (result.date) {
        const resultDate = new Date(result.date);
        processedResults.push({
          date: formatMonthYear(resultDate), // Formatear como cadena
          value: Number(result.value),
          co2_aequivalent: Number(result.co2_aequivalent),
        });
      } else if (result.start_date && result.end_date) {
        const start = new Date(result.start_date);
        const end = new Date(result.end_date);
        const totalDays = Math.floor((end - start) / (1000 * 60 * 60 * 24)) + 1;
        if (totalDays <= 0) return;

        // Distribuir el valor según el viewMode
        if (viewMode === 'daily') {
          // Distribuir el valor sobre cada día
          let currentDate = new Date(start);
          const dailyValue = Number(result.value) / totalDays;
          const dailyCO2 = Number(result.co2_aequivalent) / totalDays;

          while (currentDate <= end) {
            processedResults.push({
              date: currentDate.toISOString().split('T')[0], // Formato 'YYYY-MM-DD'
              value: dailyValue,
              co2_aequivalent: dailyCO2,
            });
            currentDate.setDate(currentDate.getDate() + 1);
          }
        } else if (viewMode === 'monthly') {
          // Distribuir el valor sobre meses
          let currentDate = new Date(start);
          const endMonth = end.getMonth() + end.getFullYear() * 12;
          const startMonth = start.getMonth() + start.getFullYear() * 12;
          const totalMonths = endMonth - startMonth + 1;
          const monthlyValue = Number(result.value) / totalMonths;
          const monthlyCO2 = Number(result.co2_aequivalent) / totalMonths;

          while (currentDate <= end) {
            const monthKey = formatMonthYear(currentDate);
            processedResults.push({
              date: monthKey, // Cadena formateada
              value: monthlyValue,
              co2_aequivalent: monthlyCO2,
            });
            // Avanzar al siguiente mes
            currentDate.setMonth(currentDate.getMonth() + 1);
          }
        } else if (viewMode === 'yearly') {
          // Distribuir el valor sobre años
          let currentYear = start.getFullYear();
          const endYear = end.getFullYear();
          const totalYears = endYear - currentYear + 1;
          const yearlyValue = Number(result.value) / totalYears;
          const yearlyCO2 = Number(result.co2_aequivalent) / totalYears;

          while (currentYear <= endYear) {
            processedResults.push({
              date: currentYear.toString(), // Cadena del año
              value: yearlyValue,
              co2_aequivalent: yearlyCO2,
            });
            currentYear++;
          }
        }
      }
    });

    let dates = [];
    let values = [];
    let co2Equivalent = [];

    if (viewMode === 'daily') {
      // Ordenar los resultados por fecha
      const sortedResults = processedResults.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );

      dates = sortedResults.map((result) => result.date); // 'YYYY-MM-DD'
      values = sortedResults.map((result) => result.value);
      co2Equivalent = sortedResults.map((result) => result.co2_aequivalent);
    } else if (viewMode === 'monthly') {
      const monthlyDataValues = {};
      const monthlyDataCO2 = {};

      processedResults.forEach((result) => {
        const monthKey = result.date; // Cadena formateada como 'Jan 2023'
        if (!monthlyDataValues[monthKey]) {
          monthlyDataValues[monthKey] = 0;
          monthlyDataCO2[monthKey] = 0;
        }
        monthlyDataValues[monthKey] += result.value;
        monthlyDataCO2[monthKey] += result.co2_aequivalent;
      });

      // Ordenar las claves de los meses
      const sortedMonths = Object.keys(monthlyDataValues)
        .sort((a, b) => {
          const [monthA, yearA] = a.split(' ');
          const [monthB, yearB] = b.split(' ');
          const dateA = new Date(`${monthA} 1, ${yearA}`);
          const dateB = new Date(`${monthB} 1, ${yearB}`);
          return dateA - dateB;
        });

      dates = sortedMonths;
      values = sortedMonths.map((monthKey) => monthlyDataValues[monthKey]);
      co2Equivalent = sortedMonths.map(
        (monthKey) => monthlyDataCO2[monthKey]
      );
    } else if (viewMode === 'yearly') {
      const yearlyDataValues = {};
      const yearlyDataCO2 = {};

      processedResults.forEach((result) => {
        const yearKey = result.date; // Cadena del año
        if (!yearlyDataValues[yearKey]) {
          yearlyDataValues[yearKey] = 0;
          yearlyDataCO2[yearKey] = 0;
        }
        yearlyDataValues[yearKey] += result.value;
        yearlyDataCO2[yearKey] += result.co2_aequivalent;
      });

      // Ordenar los años
      const sortedYears = Object.keys(yearlyDataValues).sort();

      dates = sortedYears;
      values = sortedYears.map((yearKey) => yearlyDataValues[yearKey]);
      co2Equivalent = sortedYears.map(
        (yearKey) => yearlyDataCO2[yearKey]
      );
    }

    if (dates.length === 0) {
      setData(null);
      return;
    }

    setData({
      labels: dates,
      datasets: [
        {
          label: 'Stromverbrauch (kWh)',
          data: values,
          fill: true,
          backgroundColor: 'rgba(75,192,192,0.1)',
          borderColor: 'rgba(75,192,192,1)',
          tension: 0.4,
          yAxisID: 'y',
        },
        {
          label: 'CO₂-Äquivalent (Tonnen)',
          data: co2Equivalent,
          fill: true,
          backgroundColor: 'rgba(255,159,64,0.1)',
          borderColor: 'rgba(255,159,64,1)',
          tension: 0.4,
          yAxisID: 'y1',
        },
      ],
    });

    // Configurar las opciones del gráfico
    setOptions({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
        },
        tooltip: {
          mode: 'index',
          intersect: false,
        },
      },
      scales: {
        x: {
          type: 'category', // Asegura que el eje X trate las etiquetas como categorías
          grid: {
            display: false,
          },
          ticks: {
            display: viewMode !== 'daily', // Mostrar etiquetas solo en modos mensual y anual
            // Eliminado el callback para evitar interferencias con las etiquetas
          },
        },
        y: {
          type: 'linear',
          position: 'left',
          beginAtZero: true,
          title: {
            display: true,
            text: 'kWh',
          },
        },
        y1: {
          type: 'linear',
          position: 'right',
          beginAtZero: true,
          title: {
            display: true,
            text: 'CO₂-Äquivalent (Tonnen)',
          },
          grid: {
            drawOnChartArea: false,
          },
        },
      },
    });
  }, [analysisResults, viewMode]);

  // Función para descargar el gráfico en el formato seleccionado
  const handleDownload = () => {
    const chart = chartRef.current;
    if (!chart) return;

    const url = chart.toBase64Image();
    const link = document.createElement('a');
    link.href = url;
    link.download = `stromverbrauch_co2.${downloadFormat}`;
    link.click();
  };

  return (
    <div className='container-fluid mt-4'>
      {/* Gráfico con mayor altura y separación */}
      {data && data.labels && data.labels.length > 0 ? (
        <div className="chart-container" style={{ height: '80vh', marginBottom: '40px' }}>
          <ChartComponent
            data={data}
            options={options}
            chartRef={chartRef}
            chartType={chartType}
          />
        </div>
      ) : (
        <p>Für die verfügbaren Daten sind keine Informationen vorhanden.</p>
      )}

      {/* Controles con margen inferior */}
      <div style={{ marginBottom: '20px' }}>
        <ControlsComponent
          viewMode={viewMode}
          setViewMode={setViewMode}
          chartType={chartType}
          setChartType={setChartType}
          onDownload={handleDownload}
          downloadFormat={downloadFormat}
          setDownloadFormat={setDownloadFormat}
        />
      </div>
    </div>
  );
};

export default Stromverbrauch;